import React, { Component } from "react";

import PropTypes from "prop-types";

import { ADDRESS, ADDRESS_FIELDS } from "constants/address";
import { MARKET_CENTER } from "constants/market";
import { SERVICE_METHOD } from "constants/order";

import Input from "components/StoreLocator/Strategy/Input";
import LocalTypeAhead from "components/StoreLocator/Strategy/LocalTypeAhead";
import PinDrop from "components/StoreLocator/Strategy/PinDrop";
import Select from "components/StoreLocator/Strategy/Select";

import "./SA.css";

const ADDRESS_TYPES = Object.freeze({
  [ADDRESS.HOUSE]: {
    fields: [
      ADDRESS_FIELDS[ADDRESS.ADDRESS_NICKNAME],
      ADDRESS_FIELDS[ADDRESS.ADDRESS],
      ADDRESS_FIELDS[ADDRESS.CITY],
      ADDRESS_FIELDS[ADDRESS.NEIGHBORHOOD],
      ADDRESS_FIELDS[ADDRESS.PLACE],
      ADDRESS_FIELDS[ADDRESS.STREET_NAME],
      ADDRESS_FIELDS[ADDRESS.STREET_NUMBER],
    ],
    key: ADDRESS.HOUSE,
  },
});

const MARKET_ZOOM = 10;

const optionalFields = [
  ADDRESS.NEIGHBORHOOD,
  ADDRESS.ADDRESS_NICKNAME,
  ADDRESS.DELIVERY_INSTRUCTIONS,
];

const normalizeAutoComplete = (suggestions) => {
  if (Array.isArray(suggestions))
    return suggestions.map((suggestion) =>
      typeof suggestion === "string" ? { description: suggestion } : suggestion
    );
  return Object.values(suggestions).map((suggestion) => ({
    description: suggestion,
  }));
};

const coordinateToString = (coordinate) =>
  coordinate === null ? "" : coordinate.toString();

const CityAndNeighborhood = ({
  cities,
  city,
  getNeighborhoods,
  handleChange,
  areRequired,
  neighborhood,
  neighborhoods,
  t,
}) => (
  <div className="grid">
    <LocalTypeAhead
      className="grid__cell--1 grid__cell--1/2@desktop"
      input={city}
      label={t(ADDRESS_FIELDS[ADDRESS.CITY].label)}
      name="city"
      quidBase="city-autocomplete"
      onChange={handleChange}
      onSelect={({ description }) => {
        handleChange(
          {
            city: description,
            neighborhood: "",
            latitude: null,
            longitude: null,
          },
          {
            props: {
              updateSource: getNeighborhoods,
            },
          }
        );
      }}
      required={areRequired}
      suggestions={normalizeAutoComplete(cities)}
    />
    <LocalTypeAhead
      className="grid__cell--1 grid__cell--1/2@desktop"
      input={neighborhood}
      label={t(ADDRESS_FIELDS[ADDRESS.NEIGHBORHOOD].label)}
      name="neighborhood"
      required={areRequired}
      quidBase="store-locator-neighborhood"
      onChange={handleChange}
      onSelect={({ description }) => {
        handleChange({
          neighborhood: description,
          latitude: null,
          longitude: null,
        });
      }}
      suggestions={normalizeAutoComplete(neighborhoods)}
    />
  </div>
);
class SA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invalid: [],
    };

    this.map = React.createRef();

    this.getAddressFields = this.getAddressFields.bind(this);
    this.getFormattedAddress = this.getFormattedAddress.bind(this);
    this.getGeocodedResult = this.getGeocodedResult.bind(this);
    this.handleAddressTypeChange = this.handleAddressTypeChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.updateCoordinates = this.updateCoordinates.bind(this);
    this.updateLatitude = this.updateLatitude.bind(this);
    this.updateLongitude = this.updateLongitude.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
  }

  componentDidMount() {
    const { maps, cities, getCities } = this.props;

    this.geocoderService = new maps.Geocoder();
    this.geocoderOK = maps.GeocoderStatus.OK;
    if (!Object.values(cities).length) getCities();
  }

  //eslint-disable-next-line class-methods-use-this
  getAddressFields(addressType) {
    return ADDRESS_TYPES[addressType]
      ? ADDRESS_TYPES[addressType].fields
      : ADDRESS_TYPES[ADDRESS.HOUSE].fields;
  }

  //eslint-disable-next-line class-methods-use-this
  getFormattedAddress({ city, neighborhood, address, streetNumber }) {
    const unnumberedAddress = [address, neighborhood, city].reduce(
      (formattedAddress, field) => {
        if (!field) return formattedAddress;

        return formattedAddress ? `${formattedAddress}, ${field}` : field;
      },
      ""
    );

    return streetNumber
      ? `${streetNumber} ${unnumberedAddress}`
      : unnumberedAddress;
  }

  getGeocodedResult(request, callback) {
    this.geocoderService.geocode(request, (response, status) => {
      if (status !== this.geocoderOK) {
        this.props.onError(status);
        return;
      }

      // If we have an address of type "street_address", use it.
      // If not, use the first available address component.
      const { address_components, geometry } =
        response.find((address) => address.types.includes("street_address")) ||
        response[0];

      callback({ addressComponents: address_components, geometry });
    });
  }

  handleClear() {
    const addressFields = this.getAddressFields(this.props.addressType);
    const updates = addressFields.reduce(
      (updatesObject, { mapping: fieldName }) => {
        updatesObject[fieldName] = "";
        return updatesObject;
      },
      {
        latitude: null,
        longitude: null,
      }
    );

    this.props.handleChange(updates);
  }

  handleFocus(update) {
    const [fieldName] = Object.keys(update);

    this.setState({
      invalid: this.state.invalid.filter(
        (invalidFieldName) => fieldName !== invalidFieldName
      ),
    });
  }

  handleSearch() {
    const { city, neighborhood, latitude, longitude } = this.props;

    const formattedAddress = this.getFormattedAddress({
      city,
      neighborhood,
    });

    if (latitude && longitude)
      this.map.current.dropPin({ lat: latitude, lng: longitude });
    else if (city)
      this.getGeocodedResult({ address: formattedAddress }, ({ geometry }) => {
        this.map.current.dropPin(geometry.location);
      });
  }

  updateCoordinates({
    latitude = this.props.latitude,
    longitude = this.props.longitude,
  }) {
    this.props.handleChange({
      latitude,
      longitude,
      city: "",
      neighborhood: "",
    });

    if (latitude && longitude)
      this.map.current.dropPin({ lat: latitude, lng: longitude });
  }

  updateLatitude({ latitude }) {
    const lat = parseFloat(latitude, 10);
    if (!Number.isNaN(lat))
      this.updateCoordinates({
        latitude: +latitude,
        longitude: this.props.longitude,
      });
    else
      this.props.handleChange({
        latitude: latitude === "" ? null : latitude,
        city: "",
        neighborhood: "",
      });
  }

  updateLongitude({ longitude }) {
    const lng = parseFloat(longitude, 10);
    if (!Number.isNaN(lng)) this.updateCoordinates({ longitude: lng });
    else
      this.props.handleChange({
        longitude: longitude === "" ? null : longitude,
        city: "",
        neighborhood: "",
      });
  }

  validateInputs() {
    const propKeys = Object.keys(this.props);

    // All input fields except for neighborhood are required.
    this.setState({
      invalid: Object.keys(ADDRESS_FIELDS).filter(
        (field) =>
          propKeys.includes(field) &&
          this.props[field] &&
          this.props[field].length === 0 &&
          !optionalFields.includes(field)
      ),
    });
  }

  handleAddressTypeChange(addressType) {
    this.props.handleChange({ addressType });
  }

  render() {
    const {
      addressType,
      city,
      cities,
      deliveryInstructions,
      marketConfigs: { DELIVERY_INSTRUCTIONS_MAX_LENGTH },
      neighborhood,
      neighborhoods,
      getNeighborhoods,
      handleChange,
      latitude,
      longitude,
      maps,
      serviceMethod,
      t,
    } = this.props;

    const hasLatLong = latitude && longitude;
    const mapCenter = hasLatLong
      ? { lat: latitude, lng: longitude }
      : MARKET_CENTER.SAUDI_ARABIA;

    const isLatLngRequired = latitude || longitude || !city;
    switch (serviceMethod) {
      case SERVICE_METHOD.DELIVERY:
        return (
          <div className="grid">
            <div className="grid__cell--1 grid__cell--1/2@desktop margin__bottom--1_25rem">
              <PinDrop
                mapCenter={mapCenter}
                mapZoom={MARKET_ZOOM}
                maps={maps}
                ref={this.map}
              />
            </div>
            <div className="grid__cell--1 grid__cell--1/2@desktop">
              <Select
                className="grid__cell--1"
                handleChange={this.handleAddressTypeChange}
                label={t("customer:locations.address.address_type")}
                name="addressType"
                quidBase="store-locator-address-type"
                source={Object.keys(ADDRESS_TYPES).reduce((types, type) => {
                  const { key, label } = ADDRESS_FIELDS[type];
                  types[key] = label;
                  return types;
                }, {})}
                t={t}
                value={addressType}
              />
              <div className="grid__cell--1">
                <CityAndNeighborhood
                  cities={cities}
                  city={city}
                  getNeighborhoods={getNeighborhoods}
                  handleChange={handleChange}
                  neighborhood={neighborhood}
                  neighborhoods={neighborhoods}
                  t={t}
                  areRequired={!(latitude || longitude)}
                />
              </div>
              <div className="grid__cell--1 locator-other-option">Or</div>
              <Input
                className={"grid__cell--1 grid__cell--1/2@desktop"}
                handleBlur={this.updateLatitude}
                handleChange={this.props.handleChange}
                label={t(ADDRESS_FIELDS[ADDRESS.LATITUDE].label)}
                name="latitude"
                isRequired={isLatLngRequired}
                quidBase="store-locator-address-field"
                value={coordinateToString(latitude)}
                t={t}
              />
              <Input
                className={"grid__cell--1 grid__cell--1/2@desktop"}
                handleBlur={this.updateLongitude}
                handleChange={this.props.handleChange}
                label={t(ADDRESS_FIELDS[ADDRESS.LONGITUDE].label)}
                isRequired={isLatLngRequired}
                name="longitude"
                quidBase="store-locator-address-field"
                value={coordinateToString(longitude)}
                t={t}
              />
              <Input
                className="grid__cell--1"
                handleChange={handleChange}
                label={t(ADDRESS_FIELDS[ADDRESS.DELIVERY_INSTRUCTIONS].label)}
                name="deliveryInstructions"
                quidBase="store-locator-address-field"
                value={deliveryInstructions}
                maxLength={DELIVERY_INSTRUCTIONS_MAX_LENGTH}
                t={t}
              />

              <div className="grid__cell--1">
                <div className="grid form-controls">
                  <button
                    className="btn grid__cell--1/2 grid__cell--1/4@widescreen"
                    data-icon="clear"
                    data-quid="store-locator-clear"
                    onClick={this.handleClear}
                    type="button"
                  >
                    {t("shared:action.clear_form")}
                  </button>
                  <button
                    className="btn grid__cell--1/2 grid__cell--1/4@widescreen"
                    data-icon="pin_drop"
                    data-quid="store-locator-search"
                    onClick={this.handleSearch}
                    type="button"
                  >
                    {t("shared:action.drop_pin")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case SERVICE_METHOD.CARRYOUT:
      default:
        return (
          <div>
            <CityAndNeighborhood
              cities={cities}
              city={city}
              getNeighborhoods={getNeighborhoods}
              handleChange={handleChange}
              neighborhood={neighborhood}
              neighborhoods={neighborhoods}
              t={t}
            />
          </div>
        );
    }
  }
}

SA.propTypes = {
  addressType: PropTypes.string,
  city: PropTypes.string,
  cities: PropTypes.objectOf(PropTypes.string),
  deliveryInstructions: PropTypes.objectOf(PropTypes.string),
  getCities: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  maps: PropTypes.objectOf(PropTypes.any).isRequired,
  marketConfigs: PropTypes.shape({
    DELIVERY_INSTRUCTIONS_MAX_LENGTH: PropTypes.number,
  }).isRequired,
  neighborhood: PropTypes.string,
  onError: PropTypes.func,
  //eslint-disable-next-line react/no-unused-prop-types
  organizationName: PropTypes.string,
  serviceMethod: PropTypes.oneOf(["Carryout", "Delivery", "Dine In"]),
  t: PropTypes.func.isRequired,
  //eslint-disable-next-line react/no-unused-prop-types
  unitNumber: PropTypes.string,
  neighborhoods: PropTypes.objectOf(PropTypes.string),
  getNeighborhoods: PropTypes.func,
};

SA.defaultProps = {
  address: "",
  addressType: ADDRESS.HOUSE,
  city: "",
  cities: [],
  deliveryInstructions: "",
  getCities: () => {},
  latitude: null,
  longitude: null,
  neighborhood: "",
  neighborhoods: [],
  getNeighborhoods: () => {},
  onError: (error) => {
    //eslint-disable-next-line no-console
    window.ENVCONFIG.logErrors && console.error(error);
  },
  organizationName: "",
  serviceMethod: "Carryout",
  streetName: "",
  streetNumber: "",
  unitNumber: "",
  placeId: "",
};

CityAndNeighborhood.propTypes = {
  areRequired: PropTypes.bool,
  cities: PropTypes.objectOf(PropTypes.string),
  city: PropTypes.string,
  getNeighborhoods: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  neighborhood: PropTypes.string,
  neighborhoods: PropTypes.objectOf(PropTypes.string),
  t: PropTypes.func.isRequired,
};

CityAndNeighborhood.defaultProps = {
  areRequired: true,
  cities: {},
  city: "",
  neighborhood: "",
  neighborhoods: {},
};
export default SA;
