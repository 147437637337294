/** @jsxImportSource @emotion/react */
import { Fragment } from "react";

import { Icons } from "@dpz/frankensteiner";
import classNames from "classnames";
import DOMPurify from "dompurify";
import { useFlags } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  getMarketConfigs,
  getNewExperienceFeature,
} from "ducks/market/selectors";
import { getOrderIncludesStJudeRoundUp } from "ducks/orderProduct/selectors";
import {
  getCurrentStoreAcceptAnonymousCreditCards,
  getCurrentStoreCashLimit,
} from "ducks/store/selectors";

import {
  selectCardType,
  selectExpirationMonth,
  selectExpirationYear,
  selectInvalid,
  selectPaymentProcessingError,
  selectPaymentType,
  selectPaymentTypeError,
  selectSavedCCSecurityCode,
  selectSecurityCode,
  selectTip,
  selectTipAmount,
  selectTipAmountNumeric,
  selectZipCode,
  setPaymentTypeErrorValue,
  setPaymentTypeValue,
  setSavedCCSecurityCode,
  setTipAmount,
} from "rtk_redux/slices/finishSlice";
import {
  selectPriceOrderLoading,
  selectRequireSavedCreditCardCVV,
} from "rtk_redux/slices/priceOrderSlice";

import {
  AMEX_SECURITY_CODE_LENGTH,
  CC,
  CC_Fields,
  CC_TYPES,
  CC_TYPES_FULL_NAME,
  PAYMENT_TYPES,
  TIP_KEYS,
  TIP_PERCENTAGES,
} from "constants/finish";
import {
  STJUDE_CHECKOUT_ROUND_UP_ENABLED,
  STJUDE_ENABLED,
} from "constants/launchDarkly";
import { COUNTRY_CODES } from "constants/market";
import { ORDER_TIMING, SERVICE_METHOD } from "constants/order";
import formatCurrency from "modules/formatCurrency";
import { formatDateTime } from "modules/formatDateTime";
import kebabCase from "modules/kebabCase";

import DotLoader from "components/DotLoader";
import EstimatedWaitTimes from "components/EstimatedWaitTimes";
import CCField from "components/Finish/CCField";
import Rnc from "components/Finish/Rnc";
import ProfileField from "components/ProfileField";
import StJudeRoundUp from "components/StJudeRoundUp";
import Tooltip from "components/Tooltip";

import {
  DCDIcon,
  DCDMessage,
  customTipWrapper,
  orderSavings,
  orderTotals,
  textBlue,
  tipButtonsWrapper,
  tipSection,
} from "./Finish.styles";

const Checkout = ({
  acceptableCreditCardNames = "",
  currentEstimatedWaitMinutes = null,
  currentOrderDeliveryFee = 0,
  currentOrderFoodAndBeverage = 0,
  currentOrderFutureTime = "",
  currentOrderSavings = 0,
  currentOrderTax = 0,
  currentOrderTiming = "",
  currentOrderTotal = 0,
  currentServiceMethod = SERVICE_METHOD.CARRYOUT,
  currentStoreSummary = "",
  customerEmail = "",
  customerSavedCreditCard,
  customerTaxCodes = [],
  defaultWaitTime = "",
  deliveryInstructions = "",
  deliveryInstructionsMaxLength,
  deliveryRemainingLength,
  handleCCBlur,
  handleCCChange,
  handleDeliveryInstructionsBlur,
  handleDeliveryInstructionsChange,
  handleProfileBlur,
  handleProfileFocus,
  handleSavedCCBlur,
  handleTipAmountBlur,
  handleTipChange,
  marketCode,
  orderStarted = false,
  paymentList = [],
  rncCompanyName = "",
  rncLookupNumber,
  rncNumber = "",
  rncReceiptType = "",
  rncSetCompanyName,
  rncSetNumber,
  rncSetReceiptType,
  showTip = false,
  tipLimitExceeded = false,
  subtitle = "",
  t = () => {},
  waitTimeReason = "",
}) => {
  const dispatch = useDispatch();
  const cardType = useSelector(selectCardType);
  const { DEFAULT_DATE_FORMAT } = useSelector(getMarketConfigs);

  const {
    [STJUDE_ENABLED]: stJudeEnabled,
    [STJUDE_CHECKOUT_ROUND_UP_ENABLED]: stJudeCheckoutRoundUpEnabled,
  } = useFlags();
  const orderIncludesStJudeRoundUp = useSelector(getOrderIncludesStJudeRoundUp);

  const formValues = {
    [CC.CARD_NUMBER]: "",
    [CC.EXPIRATION_MONTH]: useSelector(selectExpirationMonth),
    [CC.EXPIRATION_YEAR]: useSelector(selectExpirationYear),
    [CC.SECURITY_CODE]: useSelector(selectSecurityCode),
    [CC.ZIP_CODE]: useSelector(selectZipCode),
  };
  const savedCCSecurityCode = useSelector(selectSavedCCSecurityCode);
  const tipSelected = useSelector(selectTip);
  const tipAmount = useSelector(selectTipAmount);
  const tipAmountNumeric = useSelector(selectTipAmountNumeric);

  const paymentProcessingError = useSelector(selectPaymentProcessingError);
  const paymentType = useSelector(selectPaymentType);
  const paymentTypeError = useSelector(selectPaymentTypeError);
  const invalid = useSelector(selectInvalid);
  const isAMEX = cardType === CC_TYPES.AMEX;
  const priceOrderLoading = useSelector(selectPriceOrderLoading);
  const {
    automaticPriceOrder: shouldUseAutomaticPriceOrder,
    checkStoreCashLimit,
  } = useSelector(getNewExperienceFeature);

  const storeAcceptsAnonymousCreditCards = useSelector(
    getCurrentStoreAcceptAnonymousCreditCards
  );
  const storeCashLimit = useSelector(getCurrentStoreCashLimit);

  const isDCD = currentServiceMethod === SERVICE_METHOD.DCD;
  const creditCardAllowed = paymentList.includes(PAYMENT_TYPES.CREDIT_CARD);
  const showSavedCreditCard = creditCardAllowed && customerSavedCreditCard;
  const isSavedCreditCardSelected =
    paymentType === PAYMENT_TYPES.SAVED_CREDIT_CARD;
  const storeCashLimitExceeded =
    checkStoreCashLimit && currentOrderTotal > storeCashLimit;

  const showStJudeRoundUp =
    stJudeEnabled &&
    stJudeCheckoutRoundUpEnabled &&
    !orderIncludesStJudeRoundUp;

  const requireSavedCreditCardCVV = useSelector(
    selectRequireSavedCreditCardCVV
  );

  const PaymentType = ({ type, disabled = false, disabledMessage = "" }) => {
    const paymentRadio = (
      <>
        <input
          className="list__item-controls"
          data-quid={`pay-with-${kebabCase(type)}-input`}
          id={`payment-${type}-radio`}
          name="payment-radio"
          type="radio"
          checked={paymentType === type}
          value={type}
          disabled={disabled}
          onChange={({ target: { value } }) => {
            dispatch(setPaymentTypeErrorValue(false));
            dispatch(setPaymentTypeValue(value));
          }}
        />

        <label
          className="list__item-content"
          data-quid={`pay-with-${kebabCase(type)}-label`}
          htmlFor={`payment-${type}-radio`}
        >
          {currentServiceMethod === SERVICE_METHOD.CARRYOUT
            ? t(`finish:payment.payment_types.${type.toLowerCase()}_carryout`)
            : t(`finish:payment.payment_types.${type.toLowerCase()}`)}
        </label>
      </>
    );

    return disabled ? (
      <Tooltip title={disabledMessage}>
        <li
          className="list__item list__item--with-controls"
          data-quid={`pay-with-${kebabCase(type)}`}
        >
          {paymentRadio}
        </li>
      </Tooltip>
    ) : (
      <li
        className="list__item list__item--with-controls"
        data-quid={`pay-with-${kebabCase(type)}`}
      >
        {paymentRadio}
      </li>
    );
  };

  PaymentType.propTypes = {
    type: PropTypes.string,
    disabled: PropTypes.bool,
    disabledMessage: PropTypes.string,
  };

  const payments = paymentList.map((type) => {
    if (type === PAYMENT_TYPES.CASH && storeCashLimitExceeded)
      return (
        <PaymentType
          key={type}
          type={type}
          disabled={true}
          disabledMessage={t("negative:cash_limit_exceeded")}
        />
      );

    if (type === PAYMENT_TYPES.CREDIT_CARD && !storeAcceptsAnonymousCreditCards)
      return (
        <PaymentType
          key={type}
          type={type}
          disabled={true}
          disabledMessage={t("negative:credit_cards_cannot_be_used")}
        />
      );

    return <PaymentType key={type} type={type} />;
  });

  return (
    <Fragment>
      <p>{t(subtitle)}</p>
      <div className="grid">
        <div className="grid__cell--1 grid__cell--1/2@desktop section">
          <header className="title-group">
            <h2
              className="title-group__section"
              data-quid="heading-order-summary"
            >
              {t("finish:order_summary.title")}
            </h2>
          </header>

          <ul className="list" data-quid="order-summary">
            {!!currentOrderFoodAndBeverage && (
              <li
                className="list__item"
                data-icon="local_pizza"
                data-quid="food-and-beverage"
              >
                {t("finish:order_summary.food_and_beverage")}:{" "}
                <b css={shouldUseAutomaticPriceOrder && orderTotals}>
                  {shouldUseAutomaticPriceOrder && priceOrderLoading ? (
                    <DotLoader />
                  ) : (
                    formatCurrency(marketCode, currentOrderFoodAndBeverage)
                  )}
                </b>
              </li>
            )}

            {currentOrderDeliveryFee !== null &&
              currentServiceMethod === SERVICE_METHOD.DELIVERY && (
                <li
                  className="list__item"
                  data-icon="directions_car"
                  data-quid="delivery-charge"
                >
                  {t("finish:order_summary.delivery_charge")}:{" "}
                  <b css={shouldUseAutomaticPriceOrder && orderTotals}>
                    {shouldUseAutomaticPriceOrder && priceOrderLoading ? (
                      <DotLoader />
                    ) : (
                      formatCurrency(marketCode, currentOrderDeliveryFee)
                    )}
                  </b>
                </li>
              )}

            {!!currentOrderTax && (
              <li
                className="list__item"
                data-icon="add_circle_outline"
                data-quid="tax"
              >
                {t("finish:order_summary.tax")}:{" "}
                <b css={shouldUseAutomaticPriceOrder && orderTotals}>
                  {shouldUseAutomaticPriceOrder && priceOrderLoading ? (
                    <DotLoader />
                  ) : (
                    formatCurrency(marketCode, currentOrderTax)
                  )}
                </b>
              </li>
            )}

            {showTip && (
              <li className="list__item" data-icon="redeem" data-quid="tip">
                {t("finish:payment.tip.label")}:{" "}
                <b css={shouldUseAutomaticPriceOrder && orderTotals}>
                  {shouldUseAutomaticPriceOrder && priceOrderLoading ? (
                    <DotLoader />
                  ) : (
                    formatCurrency(marketCode, tipAmountNumeric)
                  )}
                </b>
              </li>
            )}

            <li
              className={classNames(
                "list__item",
                !shouldUseAutomaticPriceOrder && "text--center text--bigger"
              )}
              data-icon="attach_money"
              data-quid="total-price"
            >
              <b
                className={classNames(
                  shouldUseAutomaticPriceOrder && "text--bigger"
                )}
              >
                {t("finish:order_summary.total_price")}:{" "}
              </b>
              <b
                className={classNames(
                  shouldUseAutomaticPriceOrder && "text--bigger"
                )}
                css={shouldUseAutomaticPriceOrder && orderTotals}
              >
                {shouldUseAutomaticPriceOrder && priceOrderLoading ? (
                  <DotLoader />
                ) : (
                  formatCurrency(
                    marketCode,
                    showTip
                      ? currentOrderTotal + tipAmountNumeric
                      : currentOrderTotal
                  )
                )}
              </b>

              {currentOrderSavings > 0 && !shouldUseAutomaticPriceOrder && (
                <Fragment>
                  <br />
                  <span
                    className="badge list__badge--block status--positive"
                    data-quid="savings"
                  >
                    {t("finish:order_summary.savings")}:{" "}
                    <b>
                      {shouldUseAutomaticPriceOrder && priceOrderLoading ? (
                        <DotLoader />
                      ) : (
                        formatCurrency(marketCode, currentOrderSavings)
                      )}
                    </b>
                  </span>
                </Fragment>
              )}
            </li>

            {currentOrderSavings > 0 && shouldUseAutomaticPriceOrder && (
              <li
                className="list__item status--positive"
                css={shouldUseAutomaticPriceOrder && orderSavings}
                data-icon="attach_money"
                data-quid="savings"
              >
                <b>{t("finish:order_summary.savings")}: </b>
                <b css={shouldUseAutomaticPriceOrder && orderTotals}>
                  {shouldUseAutomaticPriceOrder && priceOrderLoading ? (
                    <DotLoader />
                  ) : (
                    formatCurrency(marketCode, currentOrderSavings)
                  )}
                </b>
              </li>
            )}
          </ul>

          <br />

          <ul className="list">
            {currentServiceMethod && (
              <li
                className="list__item"
                data-icon="local_phone"
                data-quid="service-method"
              >
                {t("finish:order_summary.service_method")}:{" "}
                <b>{currentServiceMethod}</b>
              </li>
            )}

            <li className="list__item" data-icon="store" data-quid="store">
              {t("finish:order_summary.store")}: <b>{currentStoreSummary}</b>
            </li>

            <li
              className="list__item"
              data-icon="access_time"
              data-quid="order-timing"
            >
              {t("finish:order_summary.order_timing")}:{" "}
              <b>
                {currentOrderTiming === ORDER_TIMING.LATER
                  ? //TODO: localize date format for market
                    formatDateTime({
                      dateTime: new Date(currentOrderFutureTime),
                      dateFormat: DEFAULT_DATE_FORMAT,
                      timeZoneAware: false,
                    })
                  : t("shared:order_timing.now")}
              </b>
            </li>

            {currentEstimatedWaitMinutes && (
              <li className="list__item" data-icon="timer" data-quid="ready-in">
                {t("finish:order_summary.ready_in")}:{" "}
                <b>
                  <EstimatedWaitTimes
                    currentEstimatedWaitMinutes={currentEstimatedWaitMinutes}
                    defaultWaitTime={defaultWaitTime}
                    t={t}
                    waitTimeReason={waitTimeReason}
                  />
                </b>
              </li>
            )}

            {orderStarted && (
              <li
                className="list__item"
                data-icon="email"
                data-quid="email-address"
              >
                {t("finish:order_summary.email_address")}:
                <ProfileField
                  handleBlur={handleProfileBlur}
                  handleFocus={handleProfileFocus}
                  id="email"
                  isInvalid={invalid.includes("email")}
                  label={t("finish:order_summary.email_address")}
                  noLabel
                  quidBase="email-address"
                  t={t}
                  value={customerEmail}
                />
              </li>
            )}
          </ul>
        </div>

        <div className="grid__cell--1 grid__cell--1/2@desktop section">
          {marketCode === COUNTRY_CODES.DOMINICAN_REPUBLIC && (
            <Rnc
              customerTaxCodes={customerTaxCodes}
              rncCompanyName={rncCompanyName}
              rncLookupNumber={rncLookupNumber}
              rncNumber={rncNumber}
              rncReceiptType={rncReceiptType}
              rncSetCompanyName={rncSetCompanyName}
              rncSetNumber={rncSetNumber}
              rncSetReceiptType={rncSetReceiptType}
              t={t}
            />
          )}

          <header className="title-group">
            <h2 className="title-group__section" data-quid="heading-payment">
              {t("finish:payment.title")}
            </h2>
          </header>
          {isDCD && (
            <div className="grid">
              <p className="msg msg--block grid__cell--1">
                <span css={DCDMessage}>
                  <Icons.SAMDCDelivery css={DCDIcon} />

                  {t("finish:payment.dcd_message")}
                </span>
              </p>
            </div>
          )}
          <ul className="list">
            {payments}
            {showSavedCreditCard && (
              <Tooltip
                title={t("finish:payment.confirm_saved_credit_card_info")}
              >
                <li
                  className="list__item list__item--with-controls"
                  data-quid="pay-with-saved-credit-card"
                >
                  <input
                    className="list__item-controls"
                    data-quid="pay-with-saved-credit-card-input"
                    id="payment-saved-credit-card-radio"
                    name="payment-radio"
                    type="radio"
                    checked={paymentType === PAYMENT_TYPES.SAVED_CREDIT_CARD}
                    value={PAYMENT_TYPES.SAVED_CREDIT_CARD}
                    onChange={({ target: { value } }) => {
                      dispatch(setPaymentTypeErrorValue(false));
                      dispatch(setPaymentTypeValue(value));
                    }}
                  />

                  <label
                    className="list__item-content"
                    data-quid="pay-with-saved-credit-card-label"
                    htmlFor="payment-saved-credit-card-radio"
                  >
                    {t("finish:payment.payment_types.saved_creditcard", {
                      cardType: DOMPurify.sanitize(
                        CC_TYPES_FULL_NAME[
                          customerSavedCreditCard.cardType.toLowerCase()
                        ]
                      ),
                      lastFour: customerSavedCreditCard.lastFour,
                    })}
                  </label>
                </li>
              </Tooltip>
            )}
          </ul>

          <div
            className={classNames("grid", {
              ["is-hidden"]: !paymentTypeError,
            })}
          >
            <p
              className="msg msg--block status--negative grid__cell--1"
              data-icon="error_outline"
            >
              {t("finish:payment.payment_type_error")}
            </p>
          </div>

          <div
            className={classNames("grid", {
              ["is-hidden"]: paymentType !== PAYMENT_TYPES.CASH,
            })}
          >
            <p
              className="msg msg--block status--positive grid__cell--1"
              data-icon="info_outline"
            >
              {t("finish:payment.cash_message", {
                total: formatCurrency(marketCode, currentOrderTotal),
              })}
            </p>
          </div>

          {requireSavedCreditCardCVV && isSavedCreditCardSelected && (
            <CCField
              className="grid__cell--1 grid__cell--1/2@desktop"
              handleChange={(value) => dispatch(setSavedCCSecurityCode(value))}
              handleBlur={handleSavedCCBlur}
              handleFocus={handleProfileFocus}
              id={CC.SAVED_SECURITY_CODE}
              isInvalid={invalid.includes(CC.SAVED_SECURITY_CODE)}
              label={t("finish:payment.confirm_security_code")}
              quidBase="payment-saved-cc-security-code"
              t={t}
              required={true}
              placeHolder={t("finish:payment.security_code")}
              maxLength={
                customerSavedCreditCard?.cardType?.toLowerCase() ===
                CC_TYPES.AMEX
                  ? AMEX_SECURITY_CODE_LENGTH
                  : "3"
              }
              value={savedCCSecurityCode}
            />
          )}
          {paymentType === PAYMENT_TYPES.CREDIT_CARD && (
            <div className="grid" data-quid="payment-card">
              <p
                className="msg msg--block status--warning grid__cell--1"
                data-icon="error_outline"
              >
                {t("finish:payment.card_message")}
              </p>
              {paymentProcessingError && (
                <p
                  className="msg msg--block status--negative grid__cell--1"
                  data-icon="error_outline"
                >
                  {t("finish:payment.processing_error")}
                </p>
              )}
              <div className="form__control-group text-field grid__cell--1">
                <p className="label">{t("finish:payment.card_type")}</p>
                {invalid.includes("cardNumber") ? (
                  <p className="error-message parsley-error">
                    {t("finish:payment.this_store_accepts", {
                      acceptedCardsNames: acceptableCreditCardNames,
                      interpolation: { escapeValue: false },
                    })}
                  </p>
                ) : (
                  cardType && (
                    <p>{t(`finish:payment.card_types.${cardType}`)}</p>
                  )
                )}
              </div>
              {Object.values(CC_Fields).map(
                ({
                  key,
                  label,
                  required,
                  className,
                  placeHolder,
                  maxLength,
                }) => {
                  return (
                    <CCField
                      className={className}
                      handleChange={handleCCChange}
                      handleBlur={handleCCBlur}
                      handleFocus={handleProfileFocus}
                      id={key}
                      isInvalid={invalid.includes(key)}
                      key={key}
                      label={t(label)}
                      quidBase={`payment-${kebabCase(key)}`}
                      t={t}
                      required={required}
                      placeHolder={placeHolder}
                      maxLength={
                        key === CC.SECURITY_CODE && isAMEX
                          ? AMEX_SECURITY_CODE_LENGTH
                          : maxLength
                      }
                      value={formValues[key]}
                    />
                  );
                }
              )}
            </div>
          )}

          {/* TIP */}
          {showTip && (
            <div css={tipSection}>
              <h3>{t("finish:payment.tip.title")}</h3>
              <div css={tipButtonsWrapper}>
                <div className="btn-group toggleable">
                  {TIP_PERCENTAGES.map(({ amount, key }) => (
                    <button
                      key={key}
                      data-quid={`tip-percentage-${key}`}
                      type="button"
                      title={key}
                      onClick={handleTipChange(key)}
                      className={classNames("btn", {
                        ["btn--fill"]: tipSelected === key,
                      })}
                    >
                      {amount}%
                    </button>
                  ))}
                </div>
                <button
                  data-quid={`tip-percentage-${TIP_KEYS.CUSTOM}`}
                  type="button"
                  title={TIP_KEYS.CUSTOM}
                  onClick={handleTipChange(TIP_KEYS.CUSTOM)}
                  className={classNames("btn", {
                    ["btn--fill"]: tipSelected === TIP_KEYS.CUSTOM,
                  })}
                >
                  {t("finish:payment.tip.custom")}
                </button>
                <button
                  data-quid={`tip-percentage-${TIP_KEYS.NONE}`}
                  type="button"
                  title={TIP_KEYS.NONE}
                  onClick={handleTipChange(TIP_KEYS.NONE)}
                  className={classNames("btn", {
                    ["btn--fill"]: tipSelected === TIP_KEYS.NONE,
                  })}
                >
                  {t("finish:payment.tip.none")}
                </button>
              </div>
              {tipSelected === TIP_KEYS.CUSTOM && (
                <div css={customTipWrapper}>
                  <CCField
                    className="grid__cell--1 grid__cell--1/2@desktop"
                    handleChange={(value) => dispatch(setTipAmount(value))}
                    handleBlur={handleTipAmountBlur(CC.TIP_AMOUNT)}
                    id={CC.TIP_AMOUNT}
                    isInvalid={invalid.includes(CC.TIP_AMOUNT)}
                    label={t("finish:payment.tip.custom_amount")}
                    quidBase="custom-tip-amount"
                    t={t}
                    required={true}
                    placeHolder={t(
                      "finish:payment.tip.custom_amount_placeholder"
                    )}
                    value={tipAmount}
                    {...(tipLimitExceeded && {
                      errorMessage: t("negative:tip_limit_exceeded"),
                    })}
                  />
                  <span css={textBlue}>
                    ({Math.round((tipAmountNumeric / currentOrderTotal) * 100)}
                    %)
                  </span>
                </div>
              )}
            </div>
          )}

          {currentServiceMethod === SERVICE_METHOD.DELIVERY && (
            <div className="section delivery-instructions">
              <header className="title-group">
                <h2 className="title-group__section">
                  <label
                    data-quid="delivery-instructions-label"
                    htmlFor="delivery-instructions"
                  >
                    {t("finish:delivery_instructions:title")}
                  </label>
                </h2>
              </header>
              <textarea
                className="text-field__input"
                data-quid="delivery-instructions"
                id="delivery-instructions"
                maxLength={deliveryInstructionsMaxLength}
                name="delivery-instructions"
                onBlur={handleDeliveryInstructionsBlur}
                onChange={handleDeliveryInstructionsChange}
                value={deliveryInstructions}
              />
              ({deliveryRemainingLength})
            </div>
          )}

          {showStJudeRoundUp && <StJudeRoundUp t={t} />}
        </div>
      </div>
    </Fragment>
  );
};

Checkout.propTypes = {
  acceptableCreditCardNames: PropTypes.string,
  currentEstimatedWaitMinutes: PropTypes.shape({
    Max: PropTypes.number,
    Min: PropTypes.number,
  }),
  currentOrderDeliveryFee: PropTypes.number,
  currentOrderFoodAndBeverage: PropTypes.number,
  currentOrderFutureTime: PropTypes.string,
  currentOrderSavings: PropTypes.number,
  currentOrderTax: PropTypes.number,
  currentOrderTiming: PropTypes.string,
  currentOrderTotal: PropTypes.number,
  currentServiceMethod: PropTypes.string,
  currentStoreSummary: PropTypes.string,
  customerEmail: PropTypes.string,
  customerSavedCreditCard: PropTypes.object,
  customerTaxCodes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  defaultWaitTime: PropTypes.string,
  deliveryInstructions: PropTypes.string,
  deliveryInstructionsMaxLength: PropTypes.number.isRequired,
  deliveryRemainingLength: PropTypes.number.isRequired,
  handleCCBlur: PropTypes.func.isRequired,
  handleCCChange: PropTypes.func.isRequired,
  handleDeliveryInstructionsBlur: PropTypes.func.isRequired,
  handleDeliveryInstructionsChange: PropTypes.func.isRequired,
  handleProfileBlur: PropTypes.func.isRequired,
  handleProfileFocus: PropTypes.func.isRequired,
  handleSavedCCBlur: PropTypes.func.isRequired,
  handleTipAmountBlur: PropTypes.func.isRequired,
  handleTipChange: PropTypes.func.isRequired,
  marketCode: PropTypes.string.isRequired,
  orderStarted: PropTypes.bool,
  paymentList: PropTypes.arrayOf(PropTypes.string),
  rncCompanyName: PropTypes.string,
  rncLookupNumber: PropTypes.func.isRequired,
  rncNumber: PropTypes.string,
  rncReceiptType: PropTypes.string,
  rncSetCompanyName: PropTypes.func.isRequired,
  rncSetNumber: PropTypes.func.isRequired,
  rncSetReceiptType: PropTypes.func.isRequired,
  showTip: PropTypes.bool,
  subtitle: PropTypes.string,
  t: PropTypes.func,
  tipLimitExceeded: PropTypes.bool,
  waitTimeReason: PropTypes.string,
};

export default Checkout;
