import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import getCurrentCities from "ducks/city/selectors";

import Select from "components/StoreLocator/Strategy/Select";

const CityDropdown = (props) => {
  const {
    className = "grid__cell--1 grid__cell--1/2@desktop",
    defaultOption,
    t,
    handleChange,
    value,
  } = props;
  const fetchedCities = useSelector(getCurrentCities);
  const selectFetchedCities = Object.assign(
    {},
    {
      selectCity: defaultOption ?? t("customer:locations.address.select_city"),
    },
    fetchedCities
  );

  return (
    <Select
      className={className}
      handleChange={handleChange}
      label={t("customer:locations.address.city")}
      name="City"
      quidBase="store-locator-address-city"
      source={selectFetchedCities}
      t={t}
      translations={false}
      value={value}
    />
  );
};

CityDropdown.propTypes = {
  className: PropTypes.string,
  defaultOption: PropTypes.string,
  t: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default CityDropdown;
