import React, { Component } from "react";

import PropTypes from "prop-types";

import { ADDRESS, ADDRESS_FIELDS } from "constants/address";
import { COUNTRY_CODES, MARKET_CENTER } from "constants/market";
import { SERVICE_METHOD } from "constants/order";
import mapAddressComponentsToQuery from "modules/mapAddressComponentsToQuery";

import "components/StoreLocator/Market/DO.css";
import GoogleMapsTypeAhead from "components/StoreLocator/Strategy/GoogleMapsTypeAhead";
import Input from "components/StoreLocator/Strategy/Input";
import PinDrop from "components/StoreLocator/Strategy/PinDrop";
import Select from "components/StoreLocator/Strategy/Select";

const ADDRESS_TYPES = Object.freeze({
  [ADDRESS.HOUSE]: {
    fields: [
      ADDRESS_FIELDS[ADDRESS.STREET_NAME],
      ADDRESS_FIELDS[ADDRESS.STREET_NUMBER],
      ADDRESS_FIELDS[ADDRESS.NEIGHBORHOOD],
      ADDRESS_FIELDS[ADDRESS.CITY],
      ADDRESS_FIELDS[ADDRESS.ADDRESS_NICKNAME],
    ],
    key: ADDRESS.HOUSE,
  },
  [ADDRESS.APARTMENT]: {
    fields: [
      ADDRESS_FIELDS[ADDRESS.APARTMENT_NAME],
      ADDRESS_FIELDS[ADDRESS.UNIT_NUMBER],
      ADDRESS_FIELDS[ADDRESS.STREET_NAME],
      ADDRESS_FIELDS[ADDRESS.STREET_NUMBER],
      ADDRESS_FIELDS[ADDRESS.NEIGHBORHOOD],
      ADDRESS_FIELDS[ADDRESS.CITY],
      ADDRESS_FIELDS[ADDRESS.ADDRESS_NICKNAME],
    ],
    key: ADDRESS.APARTMENT,
  },
  [ADDRESS.HOTEL]: {
    fields: [
      ADDRESS_FIELDS[ADDRESS.HOTEL_NAME],
      ADDRESS_FIELDS[ADDRESS.ROOM_NUMBER],
      ADDRESS_FIELDS[ADDRESS.STREET_NAME],
      ADDRESS_FIELDS[ADDRESS.STREET_NUMBER],
      ADDRESS_FIELDS[ADDRESS.NEIGHBORHOOD],
      ADDRESS_FIELDS[ADDRESS.CITY],
      ADDRESS_FIELDS[ADDRESS.ADDRESS_NICKNAME],
    ],
    key: ADDRESS.HOTEL,
  },
  [ADDRESS.PLACE]: {
    fields: [
      ADDRESS_FIELDS[ADDRESS.PLACE_NAME],
      ADDRESS_FIELDS[ADDRESS.STREET_NAME],
      ADDRESS_FIELDS[ADDRESS.STREET_NUMBER],
      ADDRESS_FIELDS[ADDRESS.NEIGHBORHOOD],
      ADDRESS_FIELDS[ADDRESS.CITY],
      ADDRESS_FIELDS[ADDRESS.ADDRESS_NICKNAME],
    ],
    key: ADDRESS.PLACE,
  },
});

const MARKET_ZOOM = 10;

const placesMethods = Object.freeze(["Carryout"]);

const optionalFields = [ADDRESS.NEIGHBORHOOD, ADDRESS.ADDRESS_NICKNAME];

const getNeighborhoodFromAddressComponents = (addressComponents) => {
  const neighborhoodComponentsTypes = [
    "neighborhood",
    "sublocality_level_1",
    "sublocality",
    "establishment",
    "political",
    "administrative_area_level_1",
  ];
  return (
    neighborhoodComponentsTypes
      .map((type) =>
        addressComponents.find(({ types = [] }) => types.includes(type))
      )
      .filter(Boolean)[0]?.long_name || ""
  );
};

class DO extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invalid: [],
    };

    this.map = React.createRef();

    this.getAddressFields = this.getAddressFields.bind(this);
    this.getFormattedAddress = this.getFormattedAddress.bind(this);
    this.getGeocodedResult = this.getGeocodedResult.bind(this);
    this.handleAddressTypeChange = this.handleAddressTypeChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handlePinDrop = this.handlePinDrop.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
  }

  componentDidMount() {
    const { maps, serviceMethod, setUsesPlaces } = this.props;

    this.geocoderService = new maps.Geocoder();
    this.geocoderOK = maps.GeocoderStatus.OK;

    placesMethods.includes(serviceMethod) && setUsesPlaces(true);
  }

  //eslint-disable-next-line class-methods-use-this
  getAddressFields(addressType) {
    return ADDRESS_TYPES[addressType]
      ? ADDRESS_TYPES[addressType].fields
      : ADDRESS_TYPES[ADDRESS.HOUSE].fields;
  }

  //eslint-disable-next-line class-methods-use-this
  getFormattedAddress({ city, neighborhood, streetName, streetNumber }) {
    const unnumberedAddress = [streetName, neighborhood, city].reduce(
      (formattedAddress, field) => {
        if (!field) return formattedAddress;

        return formattedAddress ? `${formattedAddress}, ${field}` : field;
      },
      ""
    );

    return streetNumber
      ? `${streetNumber} ${unnumberedAddress}`
      : unnumberedAddress;
  }

  getGeocodedResult(request, callback) {
    this.geocoderService.geocode(request, (response, status) => {
      if (status !== this.geocoderOK) {
        this.props.onError(status);
        return;
      }

      // If we have an address of type "street_address", use it.
      // If not, use the first available address component.
      const { address_components, geometry } =
        response.find((address) => address.types.includes("street_address")) ||
        response[0];

      callback({ addressComponents: address_components, geometry });
    });
  }

  handleClear() {
    const addressFields = this.getAddressFields(this.props.addressType);
    const updates = addressFields.reduce(
      (updatesObject, { mapping: fieldName }) => {
        updatesObject[fieldName] = "";
        return updatesObject;
      },
      {}
    );

    this.props.handleChange(updates);
  }

  handleFocus(update) {
    const [fieldName] = Object.keys(update);

    this.setState({
      invalid: this.state.invalid.filter(
        (invalidFieldName) => fieldName !== invalidFieldName
      ),
    });
  }

  handlePinDrop(latLng) {
    this.getGeocodedResult(
      { location: latLng },
      ({ addressComponents, geometry }) => {
        this.props.handleChange(
          Object.assign(
            {},
            ...Object.keys(ADDRESS_FIELDS)
              // Address type is driven by the dropdown selection
              .filter((field) => field !== ADDRESS.ADDRESS_TYPE)
              // All other fields should be reset prior to update
              .map((field) => ({ [field]: "" })),
            mapAddressComponentsToQuery(addressComponents),
            {
              neighborhood:
                getNeighborhoodFromAddressComponents(addressComponents),
            },
            {
              latitude: geometry.location.lat(),
              longitude: geometry.location.lng(),
            }
          )
        );
      }
    );
  }

  handleSearch() {
    const { city, neighborhood, streetName, streetNumber } = this.props;
    const formattedAddress = this.getFormattedAddress({
      city,
      neighborhood,
      streetName,
      streetNumber,
    });

    this.getGeocodedResult({ address: formattedAddress }, ({ geometry }) => {
      this.map.current.dropPin(geometry.location);
    });
  }

  validateInputs() {
    const propKeys = Object.keys(this.props);

    // All input fields except for neighborhood are required.
    this.setState({
      invalid: Object.keys(ADDRESS_FIELDS).filter(
        (field) =>
          propKeys.includes(field) &&
          this.props[field].length === 0 &&
          !optionalFields.includes(field)
      ),
    });
  }

  handleAddressTypeChange(addressType) {
    this.props.handleChange({ addressType });
  }

  render() {
    const {
      address,
      addressType,
      handleChange,
      latitude,
      longitude,
      maps,
      serviceMethod,
      t,
    } = this.props;

    const { handleFocus, validateInputs } = this;
    const { invalid } = this.state;
    const fieldClasses = "grid__cell--1 grid__cell--1/2@desktop";
    const mapCenter =
      latitude && longitude
        ? { lat: latitude, lng: longitude }
        : MARKET_CENTER.DOMINICAN_REPUBLIC;

    switch (serviceMethod) {
      case SERVICE_METHOD.DELIVERY:
        return (
          <div className="grid">
            <div className="grid__cell--1 grid__cell--1/2@desktop margin__bottom--1_25rem">
              <PinDrop
                mapCenter={mapCenter}
                mapZoom={MARKET_ZOOM}
                maps={maps}
                onChange={this.handlePinDrop}
                ref={this.map}
              />
            </div>
            <div className="grid__cell--1 grid__cell--1/2@desktop">
              <Select
                className="grid__cell--1"
                handleChange={this.handleAddressTypeChange}
                label={t("customer:locations.address.address_type")}
                name="addressType"
                quidBase="store-locator-address-type"
                source={Object.keys(ADDRESS_TYPES).reduce((types, type) => {
                  const { key, label } = ADDRESS_FIELDS[type];
                  types[key] = label;
                  return types;
                }, {})}
                t={t}
                value={addressType}
              />

              {this.getAddressFields(addressType).map(
                ({ label, mapping: key }) => (
                  <Input
                    className={fieldClasses}
                    handleBlur={validateInputs}
                    handleChange={handleChange}
                    handleFocus={handleFocus}
                    isInvalid={
                      invalid.includes(key) && this.props[key].length === 0
                    }
                    isRequired={!optionalFields.includes(key)}
                    key={key}
                    label={t(label)}
                    name={key}
                    quidBase="store-locator-address-field"
                    t={t}
                    value={this.props[key]}
                  />
                )
              )}

              <div className="grid__cell--1">
                <div className="grid form-controls">
                  <button
                    className="btn grid__cell--1/2 grid__cell--1/4@widescreen"
                    data-icon="clear"
                    data-quid="store-locator-clear"
                    onClick={this.handleClear}
                    type="button"
                  >
                    {t("shared:action.clear_form")}
                  </button>
                  <button
                    className="btn grid__cell--1/2 grid__cell--1/4@widescreen"
                    data-icon="pin_drop"
                    data-quid="store-locator-search"
                    onClick={this.handleSearch}
                    type="button"
                  >
                    {t("shared:action.drop_pin")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case SERVICE_METHOD.CARRYOUT:
      default:
        return (
          <GoogleMapsTypeAhead
            className="grid__cell--1"
            input={address}
            label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS].label)}
            maps={maps}
            name="address"
            onChange={({ address: addressValue, placeId }) =>
              handleChange({
                address: addressValue,
                placeId,
              })
            }
            options={{
              componentRestrictions: {
                country: [COUNTRY_CODES.DOMINICAN_REPUBLIC],
              },
              types: ["geocode"],
            }}
            quidBase="store-locator-address"
          />
        );
    }
  }
}

DO.propTypes = {
  address: PropTypes.string,
  addressType: PropTypes.string,
  city: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  maps: PropTypes.objectOf(PropTypes.any).isRequired,
  neighborhood: PropTypes.string,
  onError: PropTypes.func,
  //eslint-disable-next-line react/no-unused-prop-types
  organizationName: PropTypes.string,
  serviceMethod: PropTypes.oneOf(["Carryout", "Delivery", "Dine In"]),
  setUsesPlaces: PropTypes.func.isRequired,
  streetName: PropTypes.string,
  streetNumber: PropTypes.string,
  t: PropTypes.func.isRequired,
  //eslint-disable-next-line react/no-unused-prop-types
  unitNumber: PropTypes.string,
};

DO.defaultProps = {
  address: "",
  addressType: ADDRESS.HOUSE,
  city: "",
  latitude: null,
  longitude: null,
  neighborhood: "",
  onError: (error) => {
    //eslint-disable-next-line no-console
    window.ENVCONFIG.logErrors && console.error(error);
  },
  organizationName: "",
  serviceMethod: "Carryout",
  streetName: "",
  streetNumber: "",
  unitNumber: "",
};

export default DO;
