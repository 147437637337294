import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, filter, map, mergeMap, pluck } from "rxjs/operators";

import { pizzasRoute } from "routes";

import { UPDATE_ADDRESS_SUCCESS } from "ducks/address";
import { RESET_STATE } from "ducks/configuration";
import { getMarketConfigs } from "ducks/market/selectors";
import { updateOrderStore } from "ducks/order";

import { SERVICE_METHOD } from "constants/order";
import { ONLINE_STATUS, STORE_STATUS } from "constants/store";
import { buildErrorFromStatusItems } from "modules/errorMessageHelpers";
import isDelivery from "modules/isDelivery";

const SCOPE = "order-entry/storeLocation/";

export const LOCATE_STORES = `${SCOPE}LOCATE_STORES`;
export const LOCATE_STORES_ERROR = `${SCOPE}LOCATE_STORES_ERROR`;
export const LOCATE_STORES_SUCCESS = `${SCOPE}LOCATE_STORES_SUCCESS`;
export const LOCATE_STORES_ADDRESS_RESET = `${SCOPE}LOCATE_STORES_ADDRESS_RESET`;
export const UPDATE_DELIVERY_INSTRUCTIONS = `${SCOPE}UPDATE_DELIVERY_INSTRUCTIONS`;

const STORE_LOCATOR_URL = "/store-locator-international/locate/store";

export function locateStores({
  executeQuery = true,
  orderId = null,
  orderStoreLink = "",
  serviceMethod = SERVICE_METHOD.CARRYOUT,
  isFilterDeliveryOnlyEnabled = true,
  setOrderStore = true,
  query: {
    address = "",
    addressNickname = "",
    addressType = "",
    city = "",
    deliveryInstructions = "",
    neighborhood = "",
    organizationName = "",
    regionCode = "",
    propertyNumber = "",
    propertyType = "",
    unitNumber = "",
    unitType = "",
    placeId = "",
    // TODO: remove conditional default assignment when #CCOE-854 is implemented
    street = "",
    streetAddress1 = "",
    streetNumber = isDelivery(serviceMethod) ? "0" : "",
    streetName = "",
    postalCode = "",
    latitude = null,
    longitude = null,
  } = {},
} = {}) {
  return {
    type: LOCATE_STORES,
    executeQuery,
    orderId,
    orderStoreLink,
    power: true,
    isFilterDeliveryOnlyEnabled,
    query: {
      address,
      addressNickname,
      addressType,
      city,
      neighborhood,
      organizationName,
      regionCode,
      propertyNumber,
      propertyType,
      unitNumber,
      unitType,
      placeId,
      streetAddress1,
      street,
      streetNumber,
      streetName,
      postalCode,
      latitude,
      longitude,
      deliveryInstructions,
    },
    serviceMethod,
    setOrderStore,
    url: STORE_LOCATOR_URL,
  };
}

export function locateStoresError(error) {
  return {
    type: LOCATE_STORES_ERROR,
    error,
  };
}

export function locateStoresSuccess({
  address,
  stores = {},
  isFilterDeliveryOnlyEnabled,
} = {}) {
  return {
    type: LOCATE_STORES_SUCCESS,
    address,
    stores,
    isFilterDeliveryOnlyEnabled,
  };
}

export function updateDeliveryInstructions({ deliveryInstructions = "" } = {}) {
  return {
    type: UPDATE_DELIVERY_INSTRUCTIONS,
    deliveryInstructions,
  };
}

export function locateStoresAddressReset() {
  return {
    type: LOCATE_STORES_ADDRESS_RESET,
  };
}

export const initialState = {
  address: "",
  addressNickname: "",
  addressLine4: "",
  addressType: "",
  city: "",
  deliveryInstructions: "",
  latitude: null,
  longitude: null,
  neighborhood: "",
  organizationName: "",
  regionCode: "",
  stores: {},
  gslAddress: {},
  unitNumber: "",
};

export default function reducer(
  state = initialState,
  { address, deliveryInstructions, query, stores, type } = {}
) {
  switch (type) {
    case LOCATE_STORES:
      return { ...state, ...query };
    case LOCATE_STORES_SUCCESS:
      return {
        ...state,
        stores,
        gslAddress: address,
      };
    case LOCATE_STORES_ADDRESS_RESET:
      return { ...initialState, stores: { ...state.stores } };
    case UPDATE_DELIVERY_INSTRUCTIONS:
      return {
        ...state,
        deliveryInstructions,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

const buildStoresObject = (
  all,
  {
    AddressDescription,
    AllowDeliveryOrders,
    AllowCarryoutOrders,
    AllowDtmOrders,
    CarryoutWaitTimeReason = null,
    DeliveryWaitTimeReason = null,
    Distance = 0.0,
    IsOnlineNow,
    IsOpen,
    IsCallCenterEnabled,
    Latitude,
    Longitude,
    Phone,
    ServiceHoursDescription = {},
    ServiceMethodEstimatedWaitMinutes = {},
    StoreID,
    StoreName,
    StoreVariance,
  },
  sortOrder
) => {
  let availableServiceMethods = [];

  if (IsOnlineNow) {
    if (
      AllowCarryoutOrders &&
      ServiceHoursDescription[SERVICE_METHOD.CARRYOUT]
    ) {
      availableServiceMethods.push(SERVICE_METHOD.CARRYOUT);
    }

    if (
      AllowDeliveryOrders &&
      ServiceHoursDescription[SERVICE_METHOD.DELIVERY]
    ) {
      availableServiceMethods.push(SERVICE_METHOD.DELIVERY);
    }

    if (AllowDtmOrders && ServiceHoursDescription[SERVICE_METHOD.DTM]) {
      availableServiceMethods.push(SERVICE_METHOD.DTM);
    }
  }

  return Object.assign(all, {
    [StoreID]: {
      //TODO: translations
      addressDescription: AddressDescription,
      carryoutWaitTimeReason: CarryoutWaitTimeReason,
      deliveryWaitTimeReason: DeliveryWaitTimeReason,
      distance: Distance ? Distance.toFixed(2) : 0.0,
      hoursDescription: Object.entries(ServiceHoursDescription || {}).reduce(
        (description, [storeServiceMethod, hours]) =>
          `${
            description ? `${description}, ` : ""
          } ${storeServiceMethod}: ${hours}`,
        ""
      ),
      latitude: Latitude,
      longitude: Longitude,
      onlineStatus: IsOnlineNow ? ONLINE_STATUS.ONLINE : ONLINE_STATUS.OFFLINE,
      phone: Phone,
      serviceMethodsDescription: Object.keys(
        ServiceHoursDescription || {}
      ).join(", "),
      availableServiceMethods,
      serviceMethodEstimatedWaitMinutes:
        ServiceMethodEstimatedWaitMinutes || {},
      sortOrder,
      storeId: StoreID,
      storeName: StoreName,
      storeStatus: IsOpen ? STORE_STATUS.OPEN : STORE_STATUS.CLOSED,
      storeVariance: StoreVariance,
      isCallCenterEnabled: !!IsCallCenterEnabled,
    },
  });
};

export const locateStoresEpic = (action$, { getState }, { fetch }) =>
  action$.pipe(
    ofType(LOCATE_STORES),
    filter(({ executeQuery }) => executeQuery),
    map(({ query, ...action }) => {
      const { latitude, longitude, ...addressFields } = query;
      // If latitude and longitude don't exist, drop them from the query
      return latitude && longitude
        ? Object.assign({}, action, { query })
        : Object.assign({}, action, { query: addressFields });
    }),
    mergeMap(
      ({
        orderId,
        orderStoreLink,
        serviceMethod,
        setOrderStore,
        isFilterDeliveryOnlyEnabled,
        ...action
      }) =>
        fetch(action).pipe(
          pluck("response"),
          mergeMap(
            ({
              Address: address,
              Stores: stores,
              Status: status,
              StatusItems: statusItems,
            }) => {
              //TODO: improve coverage
              if (status < 0)
                return [
                  locateStoresError(
                    buildErrorFromStatusItems({
                      statusItems,
                    })
                  ),
                ];

              let storeId;
              let isOpen;

              const { IS_FILTER_CALL_CENTER_ENABLED } = getMarketConfigs(
                getState()
              );

              if (IS_FILTER_CALL_CENTER_ENABLED) {
                stores = stores.filter(
                  ({ IsCallCenterEnabled }) => IsCallCenterEnabled
                );
              }

              if (isFilterDeliveryOnlyEnabled && isDelivery(serviceMethod)) {
                stores = stores.filter(
                  ({ IsDeliveryStore }) => IsDeliveryStore
                );
                const store = stores[0] ? stores[0] : null;
                if (setOrderStore && store) {
                  storeId = store.StoreID;
                  isOpen = store.IsOpen;
                }
              }
              const canUpdateOrderStore = storeId && orderId;

              return [
                locateStoresSuccess({
                  address,
                  isFilterDeliveryOnlyEnabled,
                  stores: stores.reduce(buildStoresObject, {}),
                }),
              ]
                .concat(
                  canUpdateOrderStore
                    ? [
                        updateOrderStore({
                          orderId,
                          storeId,
                          url: orderStoreLink,
                        }),
                      ]
                    : []
                )
                .concat(canUpdateOrderStore && isOpen ? [pizzasRoute()] : []);
            }
          ),
          catchError((error) => {
            const {
              response: { Status: status, StatusItems: statusItems } = {},
            } = error;

            return of(
              status < 0
                ? locateStoresError(buildErrorFromStatusItems({ statusItems }))
                : locateStoresError(error)
            );
          })
        )
    )
  );

export const updateDeliveryInstructionsEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_ADDRESS_SUCCESS),
    map(({ deliveryInstructions = "" }) =>
      updateDeliveryInstructions({ deliveryInstructions })
    )
  );
export const epics = combineEpics(
  locateStoresEpic,
  updateDeliveryInstructionsEpic
);
