import React, { useRef } from "react";
import { useEffect } from "react";

import PropTypes from "prop-types";

import { ADDRESS, ADDRESS_FIELDS } from "constants/address";
import { DEFAULT_LOCATOR_STRATEGY } from "constants/locatorStrategy";
import { COUNTRY_CODES } from "constants/market";
import { MARKET_CENTER } from "constants/market";
import { SERVICE_METHOD, SERVICE_METHOD_SORT } from "constants/order";

import "components/StoreLocator/Market/CO.css";
import GoogleMapsTypeAhead from "components/StoreLocator/Strategy/GoogleMapsTypeAhead";
import Input from "components/StoreLocator/Strategy/Input";
import Select from "components/StoreLocator/Strategy/Select";

import CityDropdown from "../Strategy/CityDropDown/CityDropDown";
import PinDrop, { MAP_OPTIONS } from "../Strategy/PinDrop";

const STREET_TYPES = [
  "Anillo",
  "Autopista",
  "Avenida",
  "Avenida Calle",
  "Avenida Carrera",
  "Calle",
  "Carrera",
  "Circular",
  "Diagonal",
  "Trasversal",
  "Zona",
];

const UNIT_TYPES = ["Apartamento", "Casa", "Edificio", "Hotel", "Oficina"];

const PROPERTY_TYPES = ["Manzana", "Zona", "Bloque", "Habitación"];

const placesMethods = Object.freeze([
  SERVICE_METHOD.CARRYOUT,
  SERVICE_METHOD.DELIVERY,
]);
const MARKET_CODE = COUNTRY_CODES.COLOMBIA;

const CO = ({
  address,
  city,
  getCities,
  getRegions,
  handleChange,
  locatorStrategy,
  maps,
  neighborhood,
  regions,
  regionCode,
  streetField1 = "",
  streetField2 = "",
  streetField3 = "",
  streetNumber = "",
  serviceMethod = "",
  setHaveAddressTypes,
  setUsesPlaces,
  setRegionCode,
  t,
  unitNumber,
  propertyNumber,
}) => {
  const map = useRef(null);
  const MARKET_ZOOM = 10;

  const isDefaultLocatorStrategy = locatorStrategy === DEFAULT_LOCATOR_STRATEGY;

  useEffect(() => {
    placesMethods.includes(serviceMethod) && setUsesPlaces(true);
    setHaveAddressTypes(false);

    getRegions(MARKET_CODE);
    getCities({
      countryCode: MARKET_CODE,
    });
  }, []);

  const handleChangeSelect = (updates, addressType) => {
    handleChange({ [addressType]: updates });
  };

  const handleChangeStreet = (updates) => {
    const updatedStreetField1 = updates.streetField1 ?? streetField1;
    const updatedStreetField2 = updates.streetField2 ?? streetField2;
    const updatedStreetField3 = updates.streetField3 ?? streetField3;
    const updatedStreetNumber = updates.streetNumber ?? streetNumber;

    const updateStreetQuery = {
      street: `${updatedStreetField1} ${updatedStreetField2} ${updatedStreetField3} ${updatedStreetNumber}`,
      streetAddress1: `${updatedStreetField1} ${updatedStreetField2} ${updatedStreetField3} ${updatedStreetNumber}`,
      streetName: `${updatedStreetField1} ${updatedStreetField2} ${updatedStreetField3}`,
      streetNumber: updatedStreetNumber,
      ...updates,
    };

    handleChange(updateStreetQuery);
  };

  const handleRegionChange = (regionCode) => {
    setRegionCode(regionCode);
  };

  switch (serviceMethod) {
    case SERVICE_METHOD.DELIVERY:
      return (
        <div className="grid">
          {isDefaultLocatorStrategy && (
            <div className="grid__cell--1 grid__cell--1/2@desktop margin__bottom--1_25rem">
              <PinDrop
                mapOptions={Object.assign({}, MAP_OPTIONS, {
                  mapTypeControl: true,
                })}
                mapCenter={MARKET_CENTER.COLOMBIA}
                mapZoom={MARKET_ZOOM}
                maps={maps}
                ref={map}
              />
            </div>
          )}

          <div className="grid__cell--1 grid__cell--1/2@desktop">
            <div className="grid">
              <Select
                handleChange={handleRegionChange}
                label={t("customer:locations.address.region")}
                name="region"
                quidBase="store-locator-region"
                source={Object.freeze({
                  selectRegion: "customer:locations.address.select",
                  ...regions,
                })}
                t={t}
                value={regionCode}
                required={true}
              />
            </div>

            <div className="grid">
              <CityDropdown
                value={city}
                defaultOption={t("customer:locations.address.select")}
                t={t}
                handleChange={(val) => handleChangeSelect(val, "city")}
              />
            </div>

            <div className="grid">
              <Input
                handleChange={handleChange}
                label={t("customer:locations.address.neighborhood")}
                name="neighborhood"
                quidBase="store-locator-address-field"
                value={neighborhood}
                t={t}
              />
            </div>

            <div className="grid">
              <Select
                handleChange={(val) =>
                  handleChangeStreet({ streetField1: val })
                }
                label={t("customer:locations.address.street_field1")}
                name="StreetField1"
                quidBase="store-locator-StreetField1"
                source={Object.freeze({
                  selectRegion:
                    "customer:locations.address.select_street_field1",
                  ...STREET_TYPES.reduce((streetTypeMap, type) => {
                    streetTypeMap[type] = type;
                    return streetTypeMap;
                  }, {}),
                })}
                t={t}
                value={streetField1}
                required={true}
              />
            </div>

            <div className="grid">
              <Input
                className="grid__cell--1/4"
                handleChange={handleChangeStreet}
                label={t("customer:locations.address.street_field2")}
                name="streetField2"
                value={streetField2}
                quidBase="store-locator-address-field"
                t={t}
              />
              <div className="grid__cell--1/8 grid__cell--center">
                <span>#</span>
              </div>
              <Input
                className="grid__cell--1/4"
                handleChange={handleChangeStreet}
                name="streetField3"
                quidBase="store-locator-address-field"
                value={streetField3}
                t={t}
              />
              <div className="grid__cell--1/8 grid__cell--center">
                <span>-</span>
              </div>
              <Input
                className="grid__cell--1/4"
                handleChange={handleChangeStreet}
                name="streetNumber"
                quidBase="store-locator-address-field"
                value={streetNumber}
                t={t}
              />
            </div>

            <div className="grid">
              <Select
                className="grid__cell--2/3"
                handleChange={(val) => handleChangeSelect(val, "unitType")}
                label={t("customer:locations.address.unit_type")}
                name="unitType"
                quidBase="store-locator-UnitType"
                source={Object.freeze({
                  selectUnitType: "customer:locations.address.select",
                  ...UNIT_TYPES.reduce((unitTypeMap, type) => {
                    unitTypeMap[type] = type;
                    return unitTypeMap;
                  }, {}),
                })}
                t={t}
              />

              <Input
                className="grid__cell--1/3"
                handleChange={handleChange}
                name="unitNumber"
                quidBase="store-locator-address-field"
                value={unitNumber}
                t={t}
              />
            </div>

            <div className="grid">
              <Select
                className="grid__cell--2/3"
                handleChange={(val) => handleChangeSelect(val, "propertyType")}
                label={t("customer:locations.address.property_type")}
                name="propertyType"
                quidBase="store-locator-PropertyType"
                source={Object.freeze({
                  selectPropertyType:
                    "customer:locations.address.select_property_type",
                  ...PROPERTY_TYPES.reduce((propertyTypeMap, type) => {
                    propertyTypeMap[type] = type;
                    return propertyTypeMap;
                  }, {}),
                })}
                t={t}
              />

              <Input
                className="grid__cell--1/3"
                handleChange={handleChange}
                name="propertyNumber"
                quidBase="store-locator-address-field"
                value={propertyNumber}
                t={t}
              />
            </div>
          </div>
        </div>
      );
    case SERVICE_METHOD.CARRYOUT:
    default:
      return (
        <div className="grid">
          <GoogleMapsTypeAhead
            input={address}
            label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS].label)}
            maps={maps}
            name="address"
            onChange={({ address: addressValue, placeId }) =>
              handleChange({
                address: addressValue,
                placeId,
              })
            }
            options={{
              componentRestrictions: {
                country: [MARKET_CODE],
              },
              types: ["geocode"],
            }}
            quidBase="store-locator-address"
          />
        </div>
      );
  }
};

CO.propTypes = {
  address: PropTypes.string,
  city: PropTypes.string,
  getCities: PropTypes.func.isRequired,
  regions: PropTypes.object,
  getRegions: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  locatorStrategy: PropTypes.string.isRequired,
  setLocatorStrategy: PropTypes.func.isRequired,
  regionCode: PropTypes.string,
  maps: PropTypes.objectOf(PropTypes.any).isRequired,
  neighborhood: PropTypes.string,
  onError: PropTypes.func,
  serviceMethod: PropTypes.oneOf(SERVICE_METHOD_SORT),
  setHaveAddressTypes: PropTypes.func,
  setRegionCode: PropTypes.func.isRequired,
  setUsesPlaces: PropTypes.func.isRequired,
  streetField1: PropTypes.string,
  streetField2: PropTypes.string,
  streetField3: PropTypes.string,
  streetNumber: PropTypes.string,
  unitNumber: PropTypes.string,
  propertyNumber: PropTypes.string,
};

CO.defaultProps = {
  address: "",
  addressType: ADDRESS.LANDED,
  onError: (error) => {
    //eslint-disable-next-line no-console
    window.ENVCONFIG.logErrors && console.error(error);
  },
  serviceMethod: SERVICE_METHOD.CARRYOUT,
};

export default CO;
